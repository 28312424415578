import React from 'react';
import { Link } from 'gatsby';

import Layout from '@/components/Layout';
import { menuItems } from '@/config';
import categories from '@/content/reference-categories.json';
import SEO from '@/components/Seo';

const uri = `/referenzen`;

export default function ReferencesPage() {
  return (
    <Layout activeMenu={menuItems.references}>
      <SEO
        title="Referenzen"
        description="Referenzen"
        keywords={categories.links.map(({ label }) => label)}
      />
      <div className="content-padding">
        <ul className="sub-nav">
          {categories.links.map((ref) => (
            <li key={ref.uri}>
              <Link to={`${uri}/${ref.uri}`}>{ref.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
}
